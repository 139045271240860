<template>
  <div v-if="isFetchProduct">
    <ProductForm
      @saveDetail="updateProduct"
      :productDetail="productDetail"
      :update="true"
    />
  </div>
</template>

<script>
import ProductForm from "./ProductForm";
import { mapActions } from "vuex";
export default {
  name: "EditProduct",
  data: () => ({
    productId: "",
    productDetail: {
      name: "",
      unit: "",
      price: 0,
      sku: null,
      applicableAreas: [],
      treatmentType: {},
      ingredients: "",
      treatmentTiming: "",
      timingOverride: false,
      brandId: "",
      description: "",
      aftercaretemplate: null
    },
    isFetchProduct: false,
  }),

  components: { ProductForm },
  async created() {
    this.productId = this.$route.params.productId;
    await this.fetchProduct(this.productId);
  },
  methods: {
    ...mapActions("product", ["fetchProductDetail", "updateProductDetails"]),
    fetchProduct(productId) {
      let self = this;
      this.fetchProductDetail(productId).then((res) => {
        self.productDetail = res.data.data;
        let tempProducts = res.data.data.treatmentProducts &&
        res.data.data.treatmentProducts.map((val) => {
          return val.productStoreId
        });
        self.productDetail.treatmentProducts = tempProducts;
        self.isFetchProduct = true;
      });
    },
    updateProduct(prod, selectedTreatmentType, treatmentOptions = []) {
      let self = this;
      this.productDetail.treatmentType = selectedTreatmentType;
      this.productDetail.brandId = prod.brandId;
      this.productDetail.applicableAreas = prod.applicableAreas
      this.productDetail.ingredients = prod.ingredients
      this.productDetail.treatmentTiming = prod.treatmentTiming
      this.productDetail.timingOverride = prod.timingOverride
      this.productDetail.defaultLowBalanceAmount = prod.defaultLowBalanceAmount
      this.productDetail.unit = prod.unit
      this.productDetail.name = prod.name
      this.productDetail.description = prod.description
      this.productDetail.aftercaretemplate = prod.aftercaretemplate

      this.$vs.loading();
      this.updateProductDetails({
        id: this.productId,
        info: this.productDetail,
      }).then((res) => {
        self.$vs.notify({
          title: "Product Update",
          text: "Product Detail updated successfully",
          color: "success",
        });
        self.$vs.loading.close();

        const seletectedTreatment = treatmentOptions.filter(
          ({ _id }) => _id === selectedTreatmentType
        );
        this.productDetail.treatmentType = seletectedTreatment[0];

        // window.location.reload();
        // self.popupActivo = false;
      });
    },
  },
};
</script>

<style scoped></style>
